:root {
  --navbar-height: 54px;
  --mdc-checkbox-selected-icon-color: var(--accent-color);
  --transparent-bg: repeating-conic-gradient(var(--grey) 0% 25%, var(--white) 0% 50%) 50% / 10px 10px;
  --mat-app-body-medium-size: 16px;
}

.row {
  display: flex;
  align-items: center;

  &.align-start {
    align-items: flex-start;
  }
  &.align-center {
    align-items: center;
  }
  &.align-end {
    align-items: flex-end;
  }

  &.justify-start {
    justify-content: flex-start;
  }
  &.justify-center {
    justify-content: center;
  }
  &.justify-end {
    justify-content: flex-end;
  }

  &.space-between {
    justify-content: space-between;
  }
  &.space-around {
    justify-content: space-around;
  }
}

.column {
  display: flex;
  flex-direction: column;

  &.align-start {
    align-items: flex-start;
  }
  &.align-center {
    align-items: center;
  }
  &.align-end {
    align-items: flex-end;
  }

  &.justify-start {
    justify-content: start;
  }
  &.justify-center {
    justify-content: center;
  }
  &.justify-end {
    justify-content: flex-end;
  }

  &.space-between {
    justify-content: space-between;
  }
  &.space-around {
    justify-content: space-around;
  }
}

.align-self {
  &-start {
    align-self: flex-start;
  }
  &-end {
    align-self: flex-end;
  }
}

.full-container {
  flex: 1;
}

.full-height {
  height: 100%;
}

.full-width {
  width: 100%;
}

.inline-block {
  display: inline-block;
}

.hidden {
  display: none !important;
}

.hover-underline {
  &:hover {
    text-decoration: underline;
  }
}

.transparent-element {
  opacity: 0;
  pointer-events: none;
}

.field-input-control {
  border-radius: 3px;
  border: solid 1px var(--grey);
  background-color: var(--light-grey);
  width: 100%;
  height: 36px;
  font-size: 14px;
  padding-left: 10px;
  padding-right: 2px;
  background-clip: padding-box;

  &.field-text-area {
    padding-top: 8px;
    height: 26px;
    resize: none;
  }

  &.small {
    width: 90px;
  }

  &:not(.date) {
    &:disabled {
      background-color: var(--disabled-background-color);
      color: var(--disabled-text-color);
    }
  }

  &.disabled {
    background-color: var(--disabled-background-color);
    color: var(--disabled-text-color);
  }
}

.field-date-control {
  border-radius: 3px;
  border: solid 1px var(--grey);
  background-color: var(--light-grey);
  width: 100%;
  height: 34px;
  font-size: 14px;
  display: flex;

  .mat-datepicker-input,
  .mat-date-range-input {
    background-color: unset;
    border: unset;
    height: 100%;
    width: 100%;
    padding-left: 10px;
    display: flex;

    & + .mat-datepicker-toggle {
      .mat-mdc-icon-button {
        width: 34px;
        height: 34px;
        padding: 5px;
      }
    }

    &:disabled {
      background-color: var(--disabled-background-color);
      border-radius: 3px 0 0 3px;

      & + .mat-datepicker-toggle {
        background-color: var(--disabled-background-color);
      }
    }
  }

  .mat-datepicker-input {
    height: calc(100% - 2px);
  }

  &.small {
    width: 90px;
  }
}

.field-select-control {
  border-radius: 3px;
  width: 100%;

  &.small {
    width: 140px;
  }

  &.hidden-border:not(.ng-select-opened) {
    .ng-select-container:not(:hover) {
      background-color: unset !important;
      border-color: transparent !important;
    }
  }

  &.ng-select-disabled,
  &:disabled,
  &[readonly] {
    & > .ng-select-container {
      background-color: var(--disabled-background-color) !important;

      &:hover {
        background-color: var(--disabled-background-color) !important;
        box-shadow: none !important;
      }

      .ng-arrow-wrapper {
        display: none;
      }
    }

    &.hidden-border {
      & > .ng-select-container {
        background-color: unset !important;
        border-color: transparent !important;

        &:hover {
          background-color: unset !important;
          border-color: transparent !important;
        }

        .ng-arrow-wrapper {
          display: none;
        }
      }
    }
  }
}

mat-checkbox {
  --mdc-checkbox-selected-checkmark-color: white !important;
  --mat-checkbox-label-text-color: var(--text);
  --mat-checkbox-label-text-size: 16px;
}

mat-option {
  --mat-option-selected-state-label-text-color: var(--black);
  --mat-option-label-text-size: 12px;
}

.dark-btn {
  --mdc-text-button-label-text-color: var(--text) !important;
  --mdc-filled-button-label-text-color: var(--text) !important;
  --mdc-filled-button-container-color: var(--button) !important;
  --mdc-protected-button-container-color: var(--button) !important;
  --mdc-protected-button-label-text-color: var(--text) !important;
}

.light-btn {
  --mdc-text-button-label-text-color: var(--button) !important;
  --mdc-outlined-button-label-text-color: var(--button) !important;
  --mdc-outlined-button-outline-color: var(--button) !important;
  --mdc-filled-button-label-text-color: var(--button) !important;
}

.mat-mdc-menu-item {
  --mat-menu-item-label-text-size: 14px;
  --mat-menu-item-label-text-tracking: 0;
  --mat-menu-item-icon-color: rgba(0, 0, 0, 0.54);
}

.mat-mdc-select {
  --mat-select-trigger-text-size: 12px;
  --mat-select-trigger-text-tracking: 0;
}

.mat-mdc-button,
.mat-mdc-button-base {
  --mdc-text-button-label-text-size: 14px;
  --mdc-outlined-button-label-text-tracking: 0;
  --mdc-filled-button-label-text-tracking: 0;
  --mdc-text-button-label-text-tracking: 0;
}

mat-slide-toggle {
  --mat-switch-label-text-size: 16px;
}

.mat-datepicker-toggle {
  .mat-mdc-icon-button {
    width: 40px;
    height: 40px;
    padding: 8px;
  }
}

.mat-mdc-button,
.mat-mdc-flat-button,
.mat-mdc-icon-button {
  &[disableripple] {
    --mat-mdc-button-persistent-ripple-color: none;
  }
}

.mat-mdc-button-base {
  --mdc-filled-button-label-text-tracking: normal;
}

.mini-view {
  opacity: 0.8;
  border: solid 1px var(--outline-color);
  background-color: #293033;
  color: var(--text);
  font-size: 12px;
  left: -35px;
  padding: 5px;
  margin-top: 15px;
  user-select: none;
  margin-left: -45px;
  width: 100px;
  display: flex;
  justify-content: center;
}

.dark-menu {
  border: solid 1px var(--background-light) !important;
  background-color: var(--background-dark);
  border-radius: 0px !important;
  letter-spacing: 0;

  &.mat-mdc-menu-panel {
    background-color: var(--background-dark);
  }

  .mat-mdc-menu-content {
    letter-spacing: 0;
    color: var(--text);

    .mat-mdc-menu-item {
      --mdc-theme-text-primary-on-background: var(--white);
      --mat-menu-item-label-text-color: var(--white);
      --mat-menu-item-icon-color: var(--white);
      opacity: 0.6;

      .mat-icon {
        color: var(--text);
      }

      &:disabled span {
        color: rgba(255, 255, 255, 0.38);
      }

      &:not(:disabled):hover {
        opacity: 1;
        background-color: var(--background);

        &:after {
          content: ' ';
          position: absolute;
          left: -7px;
          right: -7px;
          bottom: -7px;
          top: -7px;
          border-width: 14px;
          border-radius: 15px;
          border-color: var(--background-dark);
          border-style: solid;
        }
      }
    }
  }

  .mat-divider {
    border-radius: 1px;
    background-color: var(--grey);
    width: 90%;
    margin: 2px 5%;
  }
}

.p-relative {
  position: relative !important;
}

.f-grow {
  flex-grow: 1;
}

#chartjs-custom-tooltip {
  position: absolute;
  min-width: 70px;
  padding: 5px 10px;
  font-size: 12px;
  color: var(--text-dark);
  pointer-events: none;
  z-index: 1000;
  background-color: var(--white);

  border: solid 1px var(--dark-grey);
  border-radius: 3px;
  box-shadow: 0 4px 6px 0 rgba(48, 51, 57, 0.2);

  &:before {
    content: '';
    position: absolute;
    border-style: solid;
    border-width: 10px 10px 0 10px;
    border-color: var(--text-dark) transparent transparent transparent;
    top: 100%;
    left: calc(50% - 10px);
  }

  &:after {
    content: '';
    position: absolute;
    border-style: solid;
    border-width: 9px 9px 0 9px;
    border-color: var(--white) transparent transparent transparent;
    top: 100%;
    left: calc(50% - 9px);
  }
}

.formula {
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  color: var(--text-dark);
  font-weight: 600;
  font-style: italic;
  font-size: 14px;
}

.overflow-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
