/*
  📄 Usage Examples:
  Use Angular Material's mat-icon-button directive with the 'datu-icon-btn' class:

  <button mat-icon-button class="datu-icon-btn">
    <mat-icon svgIcon="prev"></mat-icon>
  </button>

  .datu-icon-btn {
    --button-size: <desired size>; Set the button's width and height
    ...
  }
*/

.datu-icon-btn {
  /* Define default CSS variables */
  --button-size: 36px;
  --icon-ratio: 0.55;
  --button-color: var(--text);
  --button-background-color: transparent;

  &.mdc-icon-button.mat-mdc-button-base {
    --mdc-icon-button-icon-color: var(--button-color);
    background-color: var(--button-background-color);
    width: var(--button-size);
    height: var(--button-size);
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;

    & > *[role='img'] {
      font-size: calc(var(--button-size) / 2);
      display: inline-flex;
      align-items: center;
      justify-content: center;
      width: calc(var(--button-size) * var(--icon-ratio));
      height: calc(var(--button-size) * var(--icon-ratio));

      svg {
        fill: currentColor;
        width: 100%;
        height: 100%;
        display: block;
      }
    }

    &:disabled {
      background-color: color-mix(in srgb, var(--button-background-color) 70%, transparent);
      --mdc-icon-button-disabled-icon-color: color-mix(in srgb, var(--button-color) 40%, transparent);
    }
  }
}
